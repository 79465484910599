@charset "UTF-8";

/*
----------------------------------
Table of contents

01. Google font
02. Reset
03. Typography
04. Background color
05. Custom
06. Border
07. Margin
08. Padding
09. Display and overflow
10. Position
11. Width
12. Height
13. Header
*/

/* ===================================
    01. Google font
====================================== */
@import "../Style/font.scss";
@import "../Style/responsive.scss";
@import "../Style/color.scss";
@import "../Style/global.scss";
@import "../Style/Component/table.module.scss";
@import "../Style/Component/select.module.scss";

/* ===================================
    02. Reset
====================================== */

body::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.body input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

body {
    overflow-x: hidden !important;
}

.e-body {
    // line-height: 30px !important;
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    font-size: 1rem;
    // background-color: #ecedfd;

    ul,
    ol,
    dl {
        list-style-position: outside;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    button,
    button:focus,
    button:hover {
        outline: none;
    }

    b,
    strong {
        font-weight: 600;
    }
}

.no-transition {
    transition: all 0s ease-in-out !important;
    -moz-transition: all 0s ease-in-out !important;
    -webkit-transition: all 0s ease-in-out !important;
    -ms-transition: all 0s ease-in-out !important;
    -o-transition: all 0s ease-in-out !important;
}

.transition {
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.transition-02s {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

/* ===================================
    03. Typography
====================================== */

/* font family */
/* heading size */
.e-body {
    h2 {
        font-size: 1.5rem !important;
    }
}

/*-----------Font size ------------ */
.e-font-8 {
    font-size: 0.5rem !important;
}

.e-font-10 {
    font-size: 0.625rem !important;
}

.e-font-12 {
    font-size: 0.75rem;
}

.e-font-14 {
    font-size: 0.875rem;
}

.e-font-16 {
    font-size: 1rem;
}

.e-font-18 {
    font-size: 1.125rem;
}

.e-font-20 {
    font-size: 1.25rem;
}

.e-font-22 {
    font-size: 1.375rem;
}

.e-font-24 {
    font-size: 1.5rem;
}

.e-font-26 {
    font-size: 1.625rem;
}

.e-font-30 {
    font-size: 1.875rem;
}

.e-font-32 {
    font-size: 2rem;
}

.e-font-36 {
    font-size: 2.25rem;
}

.e-font-38 {
    font-size: 2.375rem;
}

.e-font-48 {
    font-size: 3rem;
}

.e-font-52 {
    font-size: 3.25rem;
}

.e-font-55 {
    font-size: 3.438rem;
}

/*--------- Font weight------------ */

.e-font-weight-200 {
    font-weight: 200;
}

.e-font-weight-300 {
    font-weight: 300;
}

.e-font-weight-400 {
    font-weight: 400 !important;
}

.e-font-weight-500 {
    font-weight: 500 !important;
}

.e-font-weight-600 {
    font-weight: 600 !important;
}

.e-font-weight-700 {
    font-weight: 700;
}

.e-font-weight-800 {
    font-weight: 800;
}

.e-font-weight-900 {
    font-weight: 900;
}

/* text stroke */
.text-border {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.063rem;
    -webkit-text-stroke-color: white;
}

/* text stroke width */
.text-border-width-1 {
    -webkit-text-stroke-width: 0.063rem;
}

.text-border-width-2 {
    -webkit-text-stroke-width: 0.125rem;
}

.text-border-width-3 {
    -webkit-text-stroke-width: 0.188rem;
}

.text-border-width-4 {
    -webkit-text-stroke-width: 0.25rem;
}

.text-border-width-5 {
    -webkit-text-stroke-width: 0.313rem;
}

/* text stroke color */
.text-border-color-black {
    -webkit-text-stroke-color: #000000;
}

/* Outline */
.no-outline {
    outline: none;
}

/* letter spacing */

.letter-spacing-44 {
    letter-spacing: 0.028rem;
}

.letter-spacing-3 {
    letter-spacing: 0.019rem;
}

.letter-spacing-4 {
    letter-spacing: 0.25rem;
}

.letter-spacing-5 {
    letter-spacing: 0.031rem;
}

.letter-spacing-15 {
    letter-spacing: 0.15px;
}

.letter-spacing-neg-2 {
    letter-spacing: -0.013rem;
}

.letter-spacing-02em {
    letter-spacing: 0.02em;
}

.letter-spacing-005em {
    letter-spacing: 0.005em;
}

/* transform */
.transform-scale-hover {
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}

.translateY-4px {
    transform: translateY(0.2rem);
}

.hover-arrow-wrapper:hover {
    .hover-arrow {
        transform: translateX(0.375rem);
    }
}

/* text property */
.text-normal {
    text-transform: unset !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.link-hover:hover {
    color: inherit !important;
}

.link-white-hover:hover {
    color: $white !important;
}

.text-decoration-hover-underline:hover {
    text-decoration: underline !important;
}

.word-wrap {
    word-wrap: break-word;
}

.text-transform-none {
    text-transform: none;
}

.line-height-none {
    line-height: 0;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.transform-rotate-270 {
    transform: rotate(270deg);
}

.transform-rotate-180 {
    transform: rotate(180deg);
}

.transfrom-rotate-270 {
    transform: rotate(270deg) !important;
}

/* ===================================
    05. Custom
====================================== */

.cursor-none {
    cursor: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.e-cursor-none {
    cursor: not-allowed !important;
}

.e-pointer-events-none {
    pointer-events: none;
}

/* opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}

/* z-index */
.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.z-index-5 {
    z-index: 5;
}

.z-index-6 {
    z-index: 6;
}

.z-index-7 {
    z-index: 7;
}

.z-index-8 {
    z-index: 8;
}

.z-index-9 {
    z-index: 9;
}

.z-index-99 {
    z-index: 99;
}

.z-index-0 {
    z-index: 0;
}

.z-index-minus-1 {
    z-index: -1;
}

.z-index-999 {
    z-index: 999;
}

/* ===================================
    06. Border
====================================== */
.border-transparent {
    border: 0.063rem solid transparent !important;
}

.border-1-all {
    border: 0.063rem solid !important;
}

.border-top {
    border-top: 0.063rem solid !important;
}

.e-border-1px-columbia-blue {
    border: 0.063rem solid $columbia_blue;
}

.e-border-1px-indigo {
    border: 0.063rem solid $indigo;
}

.e-border-1px-anti-flash-white {
    border: 0.063rem solid $anti_flash_white;
}

.e-border-1px-cool-grey {
    border: 0.063rem solid $cool_grey;
}

.e-border-1px-platinum-dark {
    border: 0.063rem solid $platinum_dark;
}

.e-border-1px-spanish-gray {
    border: 0.063rem solid $spanish_gray !important;
}

.e-border-1px-platinum-light {
    border: 0.063rem solid $platinum_light;
}

.e-border-2px-dashed-royal-blue {
    border: 0.125rem dashed $royal_blue;
}

.e-border-1px-lust {
    border: 0.063rem solid $lust;
}

.e-border-bottom-2px-lust {
    border-bottom: 0.125rem solid $lust;
}

.e-border-bottom-1px-dark-silver {
    border-bottom: 0.063rem solid $dark_silver;
}

.e-border-top-1px-light-steel-blue {
    border-top: 0.063rem solid $light_steel_blue;
}

.e-border-bottom-1px-indigo {
    border-bottom: 0.063rem solid $indigo;
}

.no-border-top-bottom {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.no-border-left-right {
    border-left: 0 !important;
    border-right: 0 !important;
}

/* border-radius */
.e-border-radius-2px-0-0-2px {
    border-radius: 0.125rem 0 0 0.125rem;
}

.no-border-radius {
    border-radius: 0 !important;
}

.e-border-radius-4 {
    border-radius: 0.25rem !important;
}

.e-border-radius-6 {
    border-radius: 0.375rem !important;
}

.e-border-radius-8 {
    border-radius: 0.5rem !important;
}

.e-border-radius-10 {
    border-radius: 0.625rem !important;
}

.e-border-radius-12 {
    border-radius: 0.75rem;
}

.e-border-radius-16 {
    border-radius: 1rem !important;
}

.e-border-radius-20 {
    border-radius: 1.25rem !important;
}

.e-border-radius-24 {
    border-radius: 1.5rem !important;
}

.e-border-radius-32 {
    border-radius: 2rem !important;
}

.e-border-radius-40 {
    border-radius: 2.5rem;
}

.e-border-radius-42 {
    border-radius: 2.625rem;
}

.e-border-radius-48 {
    border-radius: 3rem;
}

.e-border-radius-56 {
    border-radius: 3.558rem;
}

.e-border-radius-100px {
    border-radius: 6.25rem;
}

.e-border-radius-50per {
    border-radius: 50%;
}

.e-border-radius-8px-0-0-8px {
    border-radius: 0.5rem 0 0 0.5rem;
}

.e-border-radius-12px-0-0-12px {
    border-radius: 0.75rem 0 0 0.75rem;
}

.e-border-radius-TL-BL-24px {
    border-radius: 1.5rem 0 0 1.5rem;
}

/* ================================
            Line height
=================================== */
.line-height-0 {
    line-height: 0rem;
}

.line-height-0px {
    line-height: 0;
}

.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem !important;
}

.line-height-14px {
    line-height: 0.875rem !important;
}

.line-height-16px {
    line-height: 1rem;
}

.line-height-17px {
    line-height: 1.0625rem;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem;
}

.line-height-22px {
    line-height: 1.375rem;
}

.line-height-24px {
    line-height: 1.5rem;
}

.line-height-26px {
    line-height: 1.625rem;
}

.line-height-28px {
    line-height: 1.75rem;
}

.line-height-30px {
    line-height: 1.875rem;
}

.line-height-32px {
    line-height: 2rem;
}

.line-height-34px {
    line-height: 2.125rem;
}

.line-height-36px {
    line-height: 2.25rem;
}

.line-height-38px {
    line-height: 2.375rem;
}

.line-height-40px {
    line-height: 2.5rem;
}

.line-height-42px {
    line-height: 2.625rem;
}

.line-height-44px {
    line-height: 2.75rem;
}

.line-height-46px {
    line-height: 2.875rem;
}

.line-height-48px {
    line-height: 3rem;
}

.line-height-50px {
    line-height: 3.125rem;
}

.line-height-55px {
    line-height: 3.4375rem;
}

.line-height-60px {
    line-height: 3.75rem;
}

.line-height-62px {
    line-height: 3.875rem;
}

.line-height-65px {
    line-height: 4.0625rem;
}

.line-height-70px {
    line-height: 4.375rem;
}

.line-height-75px {
    line-height: 4.6875rem;
}

.line-height-80px {
    line-height: 5rem;
}

.line-height-85px {
    line-height: 5.3125rem;
}

.line-height-90px {
    line-height: 5.625rem;
}

.line-height-95px {
    line-height: 5.9375rem;
}

.line-height-100px {
    line-height: 6.25rem;
}

.line-height-120px {
    line-height: 7.5rem;
}

.line-height-140px {
    line-height: 8.75rem;
}

.line-height-160px {
    line-height: 10rem;
}

.line-height-170px {
    line-height: 10.625rem;
}

.e-line-height-100 {
    line-height: 100%;
}

.line-height-120 {
    line-height: 7.5rem;
}

.line-height-140 {
    line-height: 8.75rem;
}

.line-height-160 {
    line-height: 10rem;
}

.line-height-177 {
    line-height: 11.11875rem;
}

.line-height-180 {
    line-height: 11.25rem;
}

.e-line-height-120 {
    line-height: 7.5rem;
}

.e-line-height-140 {
    line-height: 8.75rem;
}

.-per {
    line-height: 9.375rem;
}

.e-line-height-160 {
    line-height: 10rem;
}

.e-line-height-161 {
    line-height: 161.523%;
}

.e-line-height-normal {
    line-height: normal;
}

.e-line-height-180 {
    line-height: 11.25rem;
}

.e-line-height-26 {
    line-height: 1.625rem;
}

.e-line-height-150-per {
    line-height: 150%;
}

/* ===================================
    07. Margin
====================================== */

.margin-2px-block {
    margin-block: 2px;
}

.margin-5-all {
    margin: 0.313rem;
}

.margin-negative-15px-left {
    margin-left: -0.938rem;
}

.margin-negative-12px-left {
    margin-left: -0.75rem;
}

/* margin bottom */

.margin-2px-bottom {
    margin-bottom: 0.125rem;
}

.margin-3px-bottom {
    margin-bottom: 0.188rem;
}

.margin-5-bottom {
    margin-bottom: 0.313rem;
}

.margin-6px-bottom {
    margin-bottom: 0.375rem;
}

.margin-7px-bottom {
    margin-bottom: 0.438rem;
}

.margin-10px-bottom {
    margin-bottom: 0.625rem;
}

.margin-12px-bottom {
    margin-bottom: 0.75rem;
}

.margin-30px-bottom {
    margin-bottom: 1.875rem;
}

.margin-32px-bottom {
    margin-bottom: 2rem;
}

.margin-34px-bottom {
    margin-bottom: 2.125rem;
}

.margin-16-bottom {
    margin-bottom: 1rem;
}

.margin-43px-bottom {
    margin-bottom: 2.675rem;
}

.margin-40px-bottom {
    margin-bottom: 2.5rem;
}

.margin-68px-bottom {
    margin-bottom: 4.25rem;
}

/* margin right */

.margin-5-right {
    margin-right: 0.313rem;
}

.margin-6-right {
    margin-right: 0.375rem;
}

.margin-12px-right {
    margin-right: 0.75rem;
}

.margin-16px-right {
    margin-right: 16px !important;
}

/* margin left */

.margin-5-left {
    margin-left: 0.313rem;
}

.margin-10-left {
    margin-left: 0.625rem;
}

.margin-12px-left {
    margin-left: 0.75rem;
}

.margin-40px-left {
    margin-left: 2.5rem;
}

.margin-28px-neg-left {
    margin-left: -1.75rem;
}

/* margin left right */

.margin-5-lr {
    margin-left: 0.313rem;
    margin-right: 0.313rem;
}

.margin-auto-lr {
    margin: 0 auto;
}

.margin-auto {
    margin: auto;
}

.margin-auto-0px {
    margin: auto 0;
}

.no-margin {
    margin: 0 !important;
}

// margin top
.no-margin-top {
    margin-top: 0 !important;
}

.margin-neg-2px-top {
    margin-top: -0.125rem;
}

.margin-neg-6px-top {
    margin-top: -0.375rem;
}

.margin-neg-8px-top {
    margin-top: -0.5rem;
}

.margin-10-top {
    margin-top: 0.625rem;
}

.margin-12px-top {
    margin-top: 0.75rem;
}


.margin-14-top {
    margin-top: 0.875rem;
}

.margin-neg-16px-top {
    margin-top: -1rem;
}

.margin-neg-20px-top {
    margin-top: -1.25rem;
}

.margin-20px-top {
    margin-top: 1.25rem;
}

.margin-22px-top {
    margin-top: 1.375rem;
}

.margin-top-neg-22px {
    margin-top: -1.375rem;
}

.margin-top-neg-24px {
    margin-top: -1.5rem;
}

.margin-48px-top {
    margin-top: 3rem;
}

.margin-neg-66px-top {
    margin-top: -4.125rem;
}

.margin-top-140px {
    margin-top: 8.75rem;
}

.margin-top-174px {
    margin-top: 10.875rem;
}

.margin-220px-top {
    margin-top: 13.75rem;
}

//negative margin top
.margin-neg-18px-top {
    margin-top: -1.125rem;
}

.margin-neg-46px-top {
    margin-top: -2.875rem;
}

.margin-neg-56px-top {
    margin-top: -3.5rem;
}

.margin-neg-98px-top {
    margin-top: -6.125rem;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.no-margin-lr {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

/* margin block  top and bottom */
.margin-block-end-0 {
    margin-block-end: 0 !important;
}

.margin-block-start-0 {
    margin-block-start: 0 !important;
}

.margin-block-32px {
    margin-block: 2rem;
}

/* ===================================
    08. Padding
====================================== */

.padding-1px-all {
    padding: 0.063rem;
}

.padding-2px-all {
    padding: 0.125rem;
}

.padding-16-all {
    padding: 1rem;
    /* 16px / 16px */
}

.padding-5px-all {
    padding: 0.3125rem;
}

.padding-8px-all {
    padding: 0.5rem;
}

.padding-10px-all {
    padding: 0.625rem;
}

.padding-12px-all {
    padding: 0.75rem;
}

.padding-16px-all {
    padding: 1rem;
}

.padding-20px-all {
    padding: 1.25rem;
}

.padding-22px-all {
    padding: 1.375rem;
}

.padding-24px-all {
    padding: 1.5rem;
}

.padding-30px-all {
    padding: 1.875rem;
}

.padding-32px-all {
    padding: 2rem;
}

.padding-35px-all {
    padding: 2.1875rem;
}

.padding-40px-all {
    padding: 2.5rem;
}

.padding-45px-all {
    padding: 2.8125rem;
}

.padding-50px-all {
    padding: 3.125rem;
}

.padding-55px-all {
    padding: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-all {
    padding: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-all {
    padding: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-all {
    padding: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-all {
    padding: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-all {
    padding: 5rem;
    /* 80px / 16px */
}

.padding-85px-all {
    padding: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-all {
    padding: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-all {
    padding: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-all {
    padding: 6.25rem;
    /* 100px / 16px */
}

.padding-34-12px {
    padding: 0.75rem 2.125rem !important;
}

.padding-2px-8px {
    padding: 0.125rem 0.5rem;
}

.e-number-input-padding {
    padding: 0.875rem 0.75rem 0.875rem 3.75rem !important;
}

.e-text-input-padding {
    padding: 0.875rem 0.75rem;
}

/* padding top */

.padding-2-top {
    padding-top: 0.125rem;
}

.padding-5-top {
    padding-top: 0.313rem;
}

.padding-6px-top {
    padding-top: 0.375rem;
}

.padding-8px-top {
    padding-top: 0.5rem;
}

.padding-10px-top {
    padding-top: 0.625rem;
}

.padding-12px-top {
    padding-top: 0.75rem;
}

.padding-14px-top {
    padding-top: 0.875rem;
}

.padding-16-top {
    padding-top: 1rem;
}

.padding-18px-top {
    padding-top: 1.125rem;
}

.padding-20px-top {
    padding-top: 1.25rem;
    /* 20px / 16px */
}

.padding-22px-top {
    padding-top: 1.375rem;
}

.padding-24-top {
    padding-top: 1.5rem;
}

.padding-25px-top {
    padding-top: 1.5625rem;
}

.padding-26px-top {
    padding-top: 1.625rem !important;
}

.padding-28px-top {
    padding-top: 1.75rem;
}

.padding-30px-top {
    padding-top: 1.875rem;
}

.padding-32px-top {
    padding-top: 2rem;
}

.padding-34px-top {
    padding-top: 2.125rem;
}

.padding-36px-top {
    padding-top: 2.25rem;
}

.padding-38px-top {
    padding-top: 2.375rem;
}

.padding-40px-top {
    padding-top: 2.5rem;
}

.padding-42px-top {
    padding-top: 2.625rem;
}

.padding-44px-top {
    padding-top: 2.75rem;
}

.padding-46px-top {
    padding-top: 2.875rem;
}

.padding-48px-top {
    padding-top: 3rem;
}

.padding-50px-top {
    padding-top: 3.125rem;
}

.padding-52px-top {
    padding-top: 3.25rem;
}

.padding-55px-top {
    padding-top: 3.4375rem;
    /* 55px / 16px */
}

.padding-54px-top {
    padding-top: 3.375rem;
}

.padding-56px-top {
    padding-top: 3.5rem;
}

.padding-58px-top {
    padding-top: 3.625rem;
}

.padding-60px-top {
    padding-top: 3.75rem;
}

.padding-62px-top {
    padding-top: 3.875rem;
}

.padding-65px-top {
    padding-top: 4.0625rem;
}

.padding-70px-top {
    padding-top: 4.375rem;
}

.padding-75px-top {
    padding-top: 4.6875rem;
}

.padding-80px-top {
    padding-top: 5rem;
    /* 80px / 16px */
}

.padding-82px-top {
    padding-top: 5.125rem;
    /* 80px / 16px */
}

.padding-84px-top {
    padding-top: 5.25rem;
    /* 80px / 16px */
}

.padding-85px-top {
    padding-top: 5.3125rem;
    /* 85px / 16px */
}

.padding-86px-top {
    padding-top: 5.375rem;
}

.padding-88px-top {
    padding-top: 5.5rem;
}

.padding-90px-top {
    padding-top: 5.625rem;
}

.padding-95px-top {
    padding-top: 5.9375rem;
}

.padding-98px-top {
    padding-top: 6.125rem;
}

.padding-100px-top {
    padding-top: 6.25rem;
}

.padding-108px-top {
    padding-top: 6.75rem;
}

.padding-132px-top {
    padding-top: 8.25rem;
}

.padding-150px-top {
    padding-top: 9.375rem;
    /* 100px / 16px */
}

.padding-160px-top {
    padding-top: 160px;
}

.padding-180px-top {
    padding-top: 11.25rem;
}

.padding-186px-top {
    padding-top: 11.625rem;
}

.padding-220px-top {
    padding-top: 13.75rem;
}

/* padding bottom */
.padding-2px-bottom {
    padding-bottom: 0.125rem;
}

.padding-5px-bottom {
    padding-bottom: 0.313rem !important;
}

.padding-6px-bottom {
    padding-bottom: 0.375rem;
}

.padding-8px-bottom {
    padding-bottom: 0.5rem;
    /* 8px / 16px */
}

.padding-10px-bottom {
    padding-bottom: 0.625rem !important;
    /* 10px / 16px */
}

.padding-12px-bottom {
    padding-bottom: 0.75rem;
    /* 10px / 16px */
}

.padding-14px-bottom {
    padding-bottom: 0.875rem;
    /* 15px / 16px */
}

.padding-16-bottom {
    padding-bottom: 1rem;
}

.padding-15px-bottom {
    padding-bottom: 0.9375rem;
    /* 15px / 16px */
}

.padding-18px-bottom {
    padding-bottom: 1.125rem;
    /* 15px / 16px */
}

.padding-16-bottom {
    padding-bottom: 1rem;
}

.padding-20px-bottom {
    padding-bottom: 1.25rem;
    /* 20px / 16px */
}

.padding-22px-bottom {
    padding-bottom: 1.375rem;
}

.padding-24px-bottom {
    padding-bottom: 1.5rem;
}

.padding-26px-bottom {
    padding-bottom: 1.625rem;
}

.padding-28px-bottom {
    padding-bottom: 1.75rem;
}

.padding-30px-bottom {
    padding-bottom: 1.875rem;
}

.padding-32px-bottom {
    padding-bottom: 2rem;
}

.padding-36px-bottom {
    padding-bottom: 2.25rem;
}

.padding-38px-bottom {
    padding-bottom: 2.375rem;
}

.padding-40px-bottom {
    padding-bottom: 2.5rem;
}

.padding-44px-bottom {
    padding-bottom: 2.75rem;
}

.padding-50px-bottom {
    padding-bottom: 3.125rem;
}

.padding-52px-bottom {
    padding-bottom: 3.25rem;
}

.padding-54px-bottom {
    padding-bottom: 3.375rem;
}

.padding-58px-bottom {
    padding-bottom: 3.625rem;
}

.padding-60px-bottom {
    padding-bottom: 3.75rem;
}

.padding-62px-bottom {
    padding-bottom: 3.875rem;
}

.padding-64px-bottom {
    padding-bottom: 4rem;
}

.padding-65px-bottom {
    padding-bottom: 4.0625rem;
}

.padding-68px-bottom {
    padding-bottom: 4.25rem;
}

.padding-70px-bottom {
    padding-bottom: 4.375rem;
}

.padding-76px-bottom {
    padding-bottom: 4.75rem;
}

.padding-80px-bottom {
    padding-bottom: 5rem;
}

.padding-88px-bottom {
    padding-bottom: 5.5rem;
}

.padding-90px-bottom {
    padding-bottom: 5.625rem;
}

.padding-92px-bottom {
    padding-bottom: 5.75rem;
}

.padding-96px-bottom {
    padding-bottom: 6rem;
}

.padding-98px-bottom {
    padding-bottom: 6.125rem;
}

.padding-100px-bottom {
    padding-bottom: 6.25rem;
}

.padding-106px-bottom {
    padding-bottom: 6.625rem;
}

.padding-120px-bottom {
    padding-bottom: 7.5rem;
}

.padding-140px-bottom {
    padding-bottom: 8.75rem;
}

.padding-100px-bottom {
    padding-bottom: 6.25rem;
}

.padding-110px-bottom {
    padding-bottom: 6.875rem;
}

.padding-120px-bottom {
    padding-bottom: 7.5rem;
}

.padding-140px-bottom {
    padding-bottom: 8.75rem;
}

.padding-150px-bottom {
    padding-bottom: 9.375rem;
}

.padding-160px-bottom {
    padding-bottom: 10rem;
}

.padding-162px-bottom {
    padding-bottom: 10.125rem;
}

.padding-174px-bottom {
    padding-bottom: 10.875rem;
}

.padding-180px-bottom {
    padding-bottom: 11.25rem;
    /* 180px / 16px */
}

.padding-186px-bottom {
    padding-bottom: 11.625rem;
}

.padding-190px-bottom {
    padding-bottom: 11.875rem;
}

.padding-100-bottom {
    padding-bottom: 6.25rem;
    /* 100% / 16px */
}

.padding-174-bottom {
    padding-bottom: 10.875rem;
}

.padding-176px-bottom {
    padding-bottom: 11rem;
}

.padding-200px-bottom {
    padding-bottom: 12.5rem;
}

.padding-210-bottom {
    padding-bottom: 13.125rem;
}

.padding-220-bottom {
    padding-bottom: 13.75rem;
}

.padding-230-bottom {
    padding-bottom: 14.375rem;
}

.padding-246px-bottom {
    padding-bottom: 15.375rem;
}

.padding-266-bottom {
    padding-bottom: 16.625rem;
}

.padding-302-bottom {
    padding-bottom: 18.875rem;
}

.padding-332-bottom {
    padding-bottom: 20.75rem;
}

/* padding left */

.padding-5-left {
    padding-left: 0.313rem;
}

.padding-10px-left {
    padding-left: 0.625rem;
}

.padding-12px-left {
    padding-left: 0.75rem;
}

.padding-15px-left {
    padding-left: 0.9375rem;
    /* 15px / 16px */
}

.padding-16-left {
    padding-left: 1rem;
}

.padding-20px-left {
    padding-left: 1.25rem;
}

.padding-24px-left {
    padding-left: 1.5rem;
}

.padding-25px-left {
    padding-left: 1.5625rem;
}

.padding-28px-left {
    padding-left: 1.75rem;
}

.padding-30px-left {
    padding-left: 1.875rem;
}

.padding-32px-left {
    padding-left: 2rem;
}

.padding-35px-left {
    padding-left: 2.1875rem;
}

.padding-36px-left {
    padding-left: 2.25rem;
}

.padding-40px-left {
    padding-left: 2.5rem !important;
}

.padding-42-left {
    padding-left: 2.625rem;
}

.padding-44px-left {
    padding-left: 2.75rem;
}

.padding-46px-left {
    padding-left: 2.875rem;
}

.padding-48px-left {
    padding-left: 3rem;
}

.padding-50px-left {
    padding-left: 3.125rem;
}

.padding-52px-left {
    padding-left: 3.25rem;
}

.padding-60px-left {
    padding-left: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-left {
    padding-left: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-left {
    padding-left: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-left {
    padding-left: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-left {
    padding-left: 5rem;
    /* 80px / 16px */
}

.padding-85px-left {
    padding-left: 5.3125rem;
    /* 85px / 16px */
}

.padding-88px-left {
    padding-left: 5.5rem;
    /* 88px / 16px */
}

.padding-90px-left {
    padding-left: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-left {
    padding-left: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-left {
    padding-left: 6.25rem;
    /* 100px / 16px */
}

.padding-116px-left {
    padding-left: 7.25rem;
    /* 100px / 16px */
}

/* padding right */
.padding-0-right {
    padding-right: 0;
}

.padding-4-right {
    padding-right: 0.25rem;
}

.padding-5-right {
    padding-right: 0.313rem;
}

.padding-10px-right {
    padding-right: 0.625rem;
}

.padding-12px-right {
    padding-right: 0.75rem;
}

.padding-16-right {
    padding-right: 1rem !important;
}

.padding-20px-right {
    padding-right: 1.25rem;
}

.padding-24px-right {
    padding-right: 1.5rem;
}

.padding-30px-right {
    padding-right: 1.875rem;
}

.padding-32px-right {
    padding-right: 2rem;
}

.padding-36px-right {
    padding-right: 2.25rem;
}

.padding-40px-right {
    padding-right: 2.5rem;
}

.padding-42-right {
    padding-right: 2.625rem;
}

.padding-46px-right {
    padding-right: 2.875rem;
}

.padding-48px-right {
    padding-right: 3rem;
}

.padding-50px-right {
    padding-right: 3.125rem;
}

.padding-56px-right {
    padding-right: 3.5rem;
}

.padding-60px-right {
    padding-right: 3.75rem;
}

.padding-65px-right {
    padding-right: 4.0625rem;
}

.padding-70px-right {
    padding-right: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-right {
    padding-right: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-right {
    padding-right: 5rem;
    /* 80px / 16px */
}

.padding-85px-right {
    padding-right: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-right {
    padding-right: 5.625rem;
    /* 90px / 16px */
}

.padding-94px-right {
    padding-right: 5.875rem;
    /* 95px / 16px */
}

.padding-95px-right {
    padding-right: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-right {
    padding-right: 6.25rem;
    /* 100px / 16px */
}

.padding-132px-right {
    padding-right: 8.25rem;
}

/* padding top bottom */

.padding-2px-tb {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.padding-4-tb {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.padding-5-tb {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
}

.padding-6-tb {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.padding-9px-tb {
    padding-block: 0.563rem !important;
}

.padding-10px-tb {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.padding-11px-tb {
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
}

.padding-12px-tb {
    padding-block: 0.75rem;
}

.padding-12px-top-10px-bottom {
    padding-top: 0.75rem;
    padding-bottom: 0.625rem;
}


.padding-14px-tb {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.padding-15px-tb {
    padding-top: 0.9375rem;
    /* 15px / 16px */
    padding-bottom: 0.9375rem;
    /* 15px / 16px */
}

.padding-20px-tb {
    padding-top: 1.25rem;
    /* 20px / 16px */
    padding-bottom: 1.25rem;
    /* 20px / 16px */
}

.padding-24px-tb {
    padding-top: 1.5rem;
    /* 24px / 16px */
    padding-bottom: 1.5rem;
    /* 24px / 16px */
}

.padding-25px-tb {
    padding-top: 1.5625rem;
    /* 25px / 16px */
    padding-bottom: 1.5625rem;
    /* 25px / 16px */
}

.padding-30px-tb {
    padding-top: 1.875rem;
    /* 30px / 16px */
    padding-bottom: 1.875rem;
    /* 30px / 16px */
}

.padding-32px-tb {
    padding-top: 2rem;
    /* 32px / 16px */
    padding-bottom: 2rem;
    /* 32px / 16px */
}

.padding-35px-tb {
    padding-top: 2.1875rem;
    /* 35px / 16px */
    padding-bottom: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-tb {
    padding-top: 2.5rem;
    /* 40px / 16px */
    padding-bottom: 2.5rem;
    /* 40px / 16px */
}

.padding-45px-tb {
    padding-top: 2.8125rem;
    /* 45px / 16px */
    padding-bottom: 2.8125rem;
    /* 45px / 16px */
}

.padding-50px-tb {
    padding-top: 3.125rem;
    /* 50px / 16px */
    padding-bottom: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-tb {
    padding-top: 3.4375rem;
    /* 55px / 16px */
    padding-bottom: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-tb {
    padding-top: 3.75rem;
    /* 60px / 16px */
    padding-bottom: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-tb {
    padding-top: 4.0625rem;
    /* 65px / 16px */
    padding-bottom: 4.0625rem;
    /* 65px / 16px */
}

.padding-70px-tb {
    padding-top: 4.375rem;
    /* 70px / 16px */
    padding-bottom: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-tb {
    padding-top: 4.6875rem;
    /* 75px / 16px */
    padding-bottom: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-tb {
    padding-top: 5rem;
    /* 80px / 16px */
    padding-bottom: 5rem;
    /* 80px / 16px */
}

.padding-85px-tb {
    padding-top: 5.3125rem;
    /* 85px / 16px */
    padding-bottom: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-tb {
    padding-top: 5.625rem;
    /* 90px / 16px */
    padding-bottom: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-tb {
    padding-top: 5.9375rem;
    /* 95px / 16px */
    padding-bottom: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-tb {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
}

/* padding left right */
.padding-5-lr {
    padding-left: 0.313rem;
    padding-right: 0.313rem;
}

.padding-6-lr {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.padding-8px-lr {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.padding-10px-lr {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.padding-12px-lr {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.padding-16px-lr {
    padding-left: 1rem;
    padding-right: 1rem;
}

.padding-20px-lr {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.padding-28px-lr {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
}

.padding-30px-lr {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}

.padding-32px-lr {
    padding-left: 2rem;
    padding-right: 2rem;
}

.padding-35px-lr {
    padding-left: 2.1875rem;
    /* 35px / 16px */
    padding-right: 2.1875rem;
    /* 35px / 16px */
}

.padding-40px-lr {
    padding-left: 2.5rem;
    /* 40px / 16px */
    padding-right: 2.5rem;
    /* 40px / 16px */
}

.padding-42px-lr {
    padding-left: 2.625rem;
    /* 42px / 16px */
    padding-right: 2.625rem;
    /* 42px / 16px */
}

.padding-45px-lr {
    padding-left: 2.8125rem;
    /* 45px / 16px */
    padding-right: 2.8125rem;
    /* 45px / 16px */
}

.padding-50px-lr {
    padding-left: 3.125rem;
    /* 50px / 16px */
    padding-right: 3.125rem;
    /* 50px / 16px */
}

.padding-55px-lr {
    padding-left: 3.4375rem;
    /* 55px / 16px */
    padding-right: 3.4375rem;
    /* 55px / 16px */
}

.padding-60px-lr {
    padding-left: 3.75rem;
    /* 60px / 16px */
    padding-right: 3.75rem;
    /* 60px / 16px */
}

.padding-65px-lr {
    padding-left: 4.0625rem;
    /* 65px / 16px */
    padding-right: 4.0625rem;
    /* 65px / 16px */
}

.padding-69px-lr {
    padding-left: 4.3125rem;
    /* 69px / 16px */
    padding-right: 4.3125rem;
    /* 69px / 16px */
}

.padding-70px-lr {
    padding-left: 4.375rem;
    /* 70px / 16px */
    padding-right: 4.375rem;
    /* 70px / 16px */
}

.padding-75px-lr {
    padding-left: 4.6875rem;
    /* 75px / 16px */
    padding-right: 4.6875rem;
    /* 75px / 16px */
}

.padding-80px-lr {
    padding-left: 5rem;
    /* 80px / 16px */
    padding-right: 5rem;
    /* 80px / 16px */
}

.padding-85px-lr {
    padding-left: 5.3125rem;
    /* 85px / 16px */
    padding-right: 5.3125rem;
    /* 85px / 16px */
}

.padding-90px-lr {
    padding-left: 5.625rem;
    /* 90px / 16px */
    padding-right: 5.625rem;
    /* 90px / 16px */
}

.padding-95px-lr {
    padding-left: 5.9375rem;
    /* 95px / 16px */
    padding-right: 5.9375rem;
    /* 95px / 16px */
}

.padding-100px-lr {
    padding-left: 6.25rem;
    /* 100px / 16px */
    padding-right: 6.25rem;
    /* 100px / 16px */
}

.padding-140px-lr {
    padding-left: 8.75rem;
    /* 140px / 16px */
    padding-right: 8.75rem;
    /* 140px / 16px */
}

.padding-62px-tb {
    padding: 3.875rem 0;
}

.no-padding {
    padding: 0 !important;
}

// padding-left right

.no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-6px-lr {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.padding-14px-lr {
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
}

.padding-52px-lr {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
}

// padding Top bottom

.no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-tb-3px-6px {
    padding: 0.188rem 0.375rem;
}

.padding-6px-tb {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}

.padding-10px-tb {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.padding-32px-tb {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.padding-36px-tb {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

/* ===================================
    09. Display and overflow
====================================== */

.d-initial {
    display: initial !important;
}

.d-grid-sip-date {
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 0.8rem;
}

.d-content {
    display: contents;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-auto {
    overflow: auto !important;
}

/* Width */

.width-24px {
    width: 1.5rem;
}

.w-100-px {
    width: 6.25rem;
}

.w-146px {
    width: 9.125rem;
}

.w-148-px {
    width: 9.25rem;
}

.w-150-px {
    width: 9.375rem;
}

.w-188-px {
    width: 11.75rem;
}

.w-348px {
    width: 21.75rem;
}

.w-520px {
    width: 32.5rem;
}

/* ===================================
    10. Position
====================================== */

.position-inherit {
    position: inherit !important;
}

/* top */

.top-auto {
    top: auto;
}

.top-inherit {
    top: inherit;
}

.top-0 {
    top: 0 !important;
}

.top-1px {
    top: 0.0625rem;
    /* 1px / 16px */
}

.top-2px {
    top: 0.125rem;
    /* 2px / 16px */
}

.top-3px {
    top: 0.1875rem;
    /* 3px / 16px */
}

.top-4px {
    top: 0.25rem;
    /* 4px / 16px */
}

.top-5px {
    top: 0.3125rem;
    /* 5px / 16px */
}

.top-6px {
    top: 0.375rem;
    /* 6px / 16px */
}

.top-7px {
    top: 0.4375rem;
    /* 7px / 16px */
}

.top-8px {
    top: 0.5rem;
    /* 8px / 16px */
}

.top-9px {
    top: 0.5625rem;
    /* 9px / 16px */
}

.top-10px {
    top: 0.625rem;
    /* 10px / 16px */
}

.top-neg-10px {
    top: -0.625rem !important;
    /* 10px / 16px */
}

.top-11px {
    top: 0.688rem;
    /* 12px / 16px */
}

.top-12px {
    top: 0.75rem;
    /* 12px / 16px */
}

.top-13px {
    top: 0.813rem;
}

.top-14px {
    top: 0.875rem;
}

.top-neg-14px {
    top: -0.875rem;
}

.top-neg-22px {
    top: -1.375rem !important;
}

.top-15px {
    top: 0.9375rem;
    /* 15px / 16px */
}

.top-16px {
    top: 1rem !important;
    /* 16px / 16px */
}

.top-neg-16px {
    top: -1rem;
}

.top-18px {
    top: 1.125rem;
}

.top-neg-18px {
    top: -1.125rem;
}

.top-20px {
    top: 1.25rem;
    /* 20px / 16px */
}

.top-22px {
    top: 1.375rem;
    /* 20px / 16px */
}

.top-24px {
    top: 1.5rem;
}

.top-neg-24px {
    top: -1.5rem;
}

.top-30px {
    top: 1.875rem;
}

.top-32px {
    top: 1.875rem;
}

.top-34px {
    top: 2.125rem;
}

.top-36px {
    top: 2.25rem;
}

.top-40px {
    top: 2.5rem;
}

.top-42px {
    top: 2.625rem;
}

.top-48px {
    top: 3rem;
}

.top-44px {
    top: 2.75rem;
}

.top-50px {
    top: 3.125rem;
}

.top-60px {
    top: 3.75rem;
}

.top-70px {
    top: 4.375rem;
}

.top-80px {
    top: 5rem;
}

.top-88px {
    top: 5.5rem;
}

.top-90px {
    top: 5.625rem;
}

.top-92px {
    top: 5.75rem;
}

.top-100px {
    top: 6.25rem;
}

.top-110px {
    top: 6.875rem;
}

.top-112px {
    top: 7rem;
}

.top-auto {
    top: auto;
}

.top-inherit {
    top: inherit;
}

.top-32 {
    top: 32%;
}

.top-40 {
    top: 40%;
}

.top-44 {
    top: 44%;
}

.top-50 {
    top: 50%;
}

/* bottom */
.bottom-0 {
    bottom: 0;
}

.bottom-8 {
    bottom: 0.5rem;
}

.bottom-17 {
    bottom: 1.063rem;
}

.bottom-30 {
    bottom: 1.875rem;
}

.bottom-70 {
    bottom: 4.375rem;
}

.bottom-auto {
    bottom: auto;
}

.bottom-57 {
    bottom: 57%;
}

.bottom-52 {
    bottom: 3.25rem;
}

.bottom-inherit {
    bottom: inherit;
}

.bottom-0px {
    bottom: 0;
}

.bottom-1px {
    bottom: 0.0625rem;
    /* 1px / 16px */
}

.bottom-2px {
    bottom: 0.125rem;
    /* 2px / 16px */
}

.bottom-3px {
    bottom: 0.1875rem;
    /* 3px / 16px */
}

.bottom-4px {
    bottom: 0.25rem;
    /* 4px / 16px */
}

.bottom-neg-4px {
    bottom: -0.25rem;
}

.bottom-5px {
    bottom: 0.3125rem;
    /* 5px / 16px */
}

.bottom-6px {
    bottom: 0.375rem;
    /* 6px / 16px */
}

.bottom-7px {
    bottom: 0.4375rem;
    /* 7px / 16px */
}

.bottom-8px {
    bottom: 0.5rem;
    /* 8px / 16px */
}

.bottom-9px {
    bottom: 0.5625rem;
    /* 9px / 16px */
}

.bottom-10px {
    bottom: 0.625rem;
    /* 10px / 16px */
}

.bottom-14px {
    bottom: 0.875rem;
    /* 10px / 16px */
}

.bottom-16px {
    bottom: 1rem;
    /* 15px / 16px */
}

.bottom-18px {
    bottom: 1.125rem;
    /* 15px / 16px */
}

.bottom-20px {
    bottom: 1.25rem;
    /* 20px / 16px */
}

.bottom-22px {
    bottom: 1.375rem;
}

.bottom-neg-22px {
    bottom: -1.375rem;
}

.bottom-24px {
    bottom: 1.5rem;
    /* 25px / 16px */
}

.bottom-26px {
    bottom: 1.625rem;
    /* 25px / 16px */
}

.bottom-30px {
    bottom: 1.875rem;
    /* 30px / 16px */
}

.bottom-34px {
    bottom: 2.125rem;
    /* 35px / 16px */
}

.bottom-40px {
    bottom: 2.5rem;
    /* 40px / 16px */
}

.bottom-45px {
    bottom: 2.8125rem;
    /* 45px / 16px */
}

.bottom-50px {
    bottom: 3.125rem;
    /* 50px / 16px */
}

.bottom-52px {
    bottom: 3.25rem;
    /* 50px / 16px */
}

.bottom-56px {
    bottom: 3.5rem;
    /* 55px / 16px */
}

.bottom-60px {
    bottom: 3.75rem;
    /* 60px / 16px */
}

.bottom-65px {
    bottom: 4.0625rem;
    /* 65px / 16px */
}

.bottom-70px {
    bottom: 4.375rem;
    /* 70px / 16px */
}

.bottom-75px {
    bottom: 4.6875rem;
    /* 75px / 16px */
}

.bottom-80px {
    bottom: 5rem;
    /* 80px / 16px */
}

.bottom-85px {
    bottom: 5.3125rem;
    /* 85px / 16px */
}

.bottom-90px {
    bottom: 5.625rem;
    /* 90px / 16px */
}

.bottom-95px {
    bottom: 5.9375rem;
    /* 95px / 16px */
}

.bottom-100px {
    bottom: 6.25rem;
    /* 100px / 16px */
}

.bottom-auto {
    bottom: auto;
}

.bottom-57 {
    bottom: 3.5625rem;
    /* 57% based on a parent's height */
}

.bottom-neg-116px {
    bottom: -7.25rem;
}


.bottom-inherit {
    bottom: inherit;
}

/* right negative */

.right-5px-negative {
    right: -0.3125rem;
}

.right-neg-10px {
    right: -0.625rem;
}

.right-0px {
    right: 0 !important;
}

.right-1px {
    right: 0.0625rem;
}

.right-2px {
    right: 0.125rem;
}

.right-3px {
    right: 0.1875rem;
}

.right-4px {
    right: 0.25rem;
}

.right-6px {
    right: 0.375rem;
}

.right-8px {
    right: 0.5rem;
}

.right-10px {
    right: 0.625rem;
}

.right-12px {
    right: 0.75rem;
}

.right-14px {
    right: 0.875rem;
}

.right-neg-14px {
    right: -0.875rem;
}

.right-16px {
    right: 1rem;
}

.right-20px {
    right: 1.25rem;
}

.right-24px {
    right: 1.5rem;
}

.right-28px {
    right: 1.75rem;
}

.right-30px {
    right: 1.875rem;
}

.right-35px {
    right: 2.1875rem;
    /* 35px / 16px */
}

.right-36px {
    right: 2.25rem;
    /* 35px / 16px */
}

.right-40px {
    right: 2.5rem;
    /* 40px / 16px */
}

.right-45px {
    right: 2.8125rem;
    /* 45px / 16px */
}

.right-50px {
    right: 3.125rem;
    /* 50px / 16px */
}

.right-55px {
    right: 3.4375rem;
    /* 55px / 16px */
}

.right-60px {
    right: 3.75rem;
    /* 60px / 16px */
}

.right-64px {
    right: 4rem;
    /* 60px / 16px */
}

.right-80px {
    right: 5rem;
    /* 80px / 16px */
}

.right-108px {
    right: 6.75rem;
    /* 80px / 16px */
}

.right-auto {
    right: auto;
}

.right-inherit {
    right: inherit;
}

/* left */
.left-0 {
    left: 0;
}

.left-28 {
    left: 28%;
}

.left-40 {
    left: 40%;
}

.left-44 {
    left: 44%;
}

.left-46 {
    left: 46%;
}

.left-46px {
    left: 2.875rem;
}

.left-48 {
    left: 48%;
}

.left-50 {
    left: 50%;
}

.left-auto {
    left: auto;
}

.left-inherit {
    left: inherit;
}

.left-18px-neg {
    left: -1.125rem !important;
    /* 16px / 16px */
}

.left-0px {
    left: 0;
}

.left-1px {
    left: 0.0625rem;
    /* 1px / 16px */
}

.left-2px {
    left: 0.125rem;
}

.left-3px {
    left: 0.1875rem;
}

.left-4px {
    left: 0.25rem;
}

.left-6px {
    left: 0.375rem;
}

.left-8px {
    left: 0.5rem;
}

.left-10px {
    left: 0.625rem;
}

.left-12px {
    left: 0.75rem;
}

.left-14px {
    left: 0.875rem;
}

.left-16px {
    left: 1rem;
}

.left-18px {
    left: 1.125rem;
}

.left-20px {
    left: 1.25rem;
}

.left-24px {
    left: 1.5rem;
}

.left-30px {
    left: 1.875rem;
}

.left-32px {
    left: 2rem;
    /* 30px / 16px */
}

.left-35px {
    left: 2.1875rem;
    /* 35px / 16px */
}

.left-40px {
    left: 2.5rem;
}

.left-50px {
    left: 3.125rem;
}

.left-110px {
    left: 6.875rem;
}

.left-auto {
    left: auto;
}

.left-inherit {
    left: inherit;
}

/* ===================================
    11. Width
====================================== */
.w-0 {
    width: 0;
}

.w-1rem {
    width: 0.063rem !important;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-57 {
    width: 57%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.w-0px {
    width: 0;
}

.w-1px {
    width: 0.0625rem;
    /* 1px / 16px */
}

.w-2px {
    width: 0.125rem;
    /* 2px / 16px */
}

.w-3px {
    width: 0.1875rem;
    /* 3px / 16px */
}

.w-4px {
    width: 0.25rem;
    /* 4px / 16px */
}

.w-5px {
    width: 0.3125rem;
    /* 5px / 16px */
}

.w-6px {
    width: 0.375rem;
}

.w-8px {
    width: 0.5rem;
}

.w-10px {
    width: 0.625rem;
}

.w-15px {
    width: 0.9375rem;
}

.w-16px {
    width: 1rem;
}

.w-18px {
    width: 1.125rem;
}

.w-20px {
    width: 1.25rem;
}

.w-22px {
    width: 1.375rem;
}

.w-24px {
    width: 1.5rem;
}

.w-26px {
    width: 1.625rem;
}

.w-30px {
    width: 1.875rem;
}

.w-34px {
    width: 2.125rem;
}

.w-40px {
    width: 2.5rem;
}

.w-44px {
    width: 2.75rem;
}

.w-48px {
    width: 3rem;
}

.w-50px {
    width: 3.125rem;
}

.w-55px {
    width: 3.4375rem;
}

.w-60px {
    width: 3.75rem;
}

.w-66px {
    width: 4.125rem;
}

.w-68px {
    width: 4.25rem;
}

.w-70px {
    width: 4.375rem;
}

.w-78px {
    width: 4.875rem;
}

.w-80px {
    width: 5rem;
}

.w-82px {
    width: 5.125rem;
}

.w-86px {
    width: 5.375rem;
}

.w-90px {
    width: 5.625rem;
}

.w-100px {
    width: 6.25rem;
}

.w-104px {
    width: 6.5rem;
}

.w-106px {
    width: 106px;
}

.w-110px {
    width: 6.875rem;
}

.w-120px {
    width: 7.5rem;
}

.w-130px {
    width: 8.125rem;
}

.w-132px {
    width: 8.25rem;
}


.w-136px {
    width: 8.5rem;
}

.w-140px {
    width: 8.75rem;
}

.w-148px {
    width: 9.25rem;
}

.w-150px {
    width: 9.375rem;
}

.w-160px {
    width: 10rem;
    /* 160px / 16px */
}

.w-164px {
    width: 164px;
}

.w-168px {
    width: 168px;
}

.w-170px {
    width: 10.625rem;
    /* 170px / 16px */
}

.w-174px {
    width: 174px;
    /* 170px / 16px */
}

.w-180px {
    width: 11.25rem;
    /* 180px / 16px */
}

.w-186px {
    width: 186px;
}

.w-190px {
    width: 11.875rem;
    /* 190px / 16px */
}

.w-200px {
    width: 12.5rem;
}

.w-236px {
    width: 236px;
}

.w-250px {
    width: 15.625rem;
}

.w-300px {
    width: 18.75rem;
}

.w-310px {
    width: 19.375rem;
}

.w-350px {
    width: 21.875rem;
}

.w-358px {
    width: 22.375rem;
}

.w-374px {
    width: 23.375rem;
}

.w-400px {
    width: 25rem;
}

.w-412px {
    width: 25.75rem;
}

.w-450px {
    width: 28.125rem;
}

.w-460px {
    width: 28.75rem;
}

.w-480px {
    width: 30rem;
}

.w-500px {
    width: 31.25rem;
}

.w-540px {
    width: 33.75rem;
}

.w-550px {
    width: 34.375rem;
    /* 550px / 16px */
}

.w-600px {
    width: 37.5rem;
    /* 600px / 16px */
}

.w-650px {
    width: 40.625rem;
    /* 650px / 16px */
}

.w-700px {
    width: 43.75rem;
    /* 700px / 16px */
}

.w-750px {
    width: 46.875rem;
    /* 750px / 16px */
}

.w-800px {
    width: 50rem;
    /* 800px / 16px */
}

.w-850px {
    width: 53.125rem;
    /* 850px / 16px */
}

.w-900px {
    width: 56.25rem;
    /* 900px / 16px */
}

.w-950px {
    width: 59.375rem;
    /* 950px / 16px */
}

.w-1000px {
    width: 62.5rem;
    /* 1000px / 16px */
}

.w-10 {
    width: 1.625rem;
    /* 10% of the parent container's width */
}

.w-15 {
    width: 2.5rem;
    /* 15% of the parent container's width */
}

.w-20 {
    width: 3.125rem;
    /* 20% of the parent container's width */
}

.w-25 {
    width: 3.90625rem;
    /* 25% of the parent container's width */
}

.w-32 {
    width: 2rem;
}

.w-35 {
    width: 5.46875rem;
    /* 35% of the parent container's width */
}

.w-38 {
    width: 2.375rem;
}

.w-45 {
    width: 7.03125rem;
    /* 45% of the parent container's width */
}

.w-50 {
    width: 7.8125rem;
    /* 50% of the parent container's width */
}

.w-55 {
    width: 8.59375rem;
    /* 55% of the parent container's width */
}

.w-57 {
    width: 9.0625rem;
    /* 57% of the parent container's width */
}

.w-60 {
    width: 9.375rem;
    /* 60% of the parent container's width */
}

.w-65 {
    width: 10.46875rem;
    /* 65% of the parent container's width */
}

.w-70 {
    width: 11.5625rem;
    /* 70% of the parent container's width */
}

.w-75 {
    width: 12.65625rem;
    /* 75% of the parent container's width */
}

.w-80 {
    width: 13.75rem;
    /* 80% of the parent container's width */
}

.w-80-per {
    width: 80%;
    /* 80% of the parent container's width */
}

.w-85 {
    width: 14.84375rem;
    /* 85% of the parent container's width */
}

.w-90 {
    width: 15.9375rem;
    /* 90% of the parent container's width */
}

.w-95 {
    width: 17.03125rem;
    /* 95% of the parent container's width */
}

.w-72 {
    width: 72%;
}

.w-270 {
    width: 16.875rem !important;
}

.w-100 {
    width: 18.125rem;
}

.w-210px {
    width: 13.125rem;
}

.w-158px {
    width: 9.875rem;
}

.w-300 {
    width: 18.75rem;
}

.w-auto {
    width: auto;
}

.w-max-content {
    width: max-content !important;
}

.w-fit-content {
    width: fit-content;
}

/* ===================================
    12. Height
====================================== */
.h-max-content {
    height: max-content !important;
}

.h-1 {
    height: 0.063rem;
}

.h-2 {
    height: 0.125rem;
}

.h-6 {
    height: 0.375rem;
}

.h-24 {
    height: 1.5rem;
}

.h-28 {
    height: 1.75rem;
}

.h-16 {
    height: 1rem;
}

.h-18px {
    height: 1.125rem;
}

.h-20px {
    height: 1.25rem;
}

.h-22px {
    height: 1.375rem;
}

.h-24px {
    height: 1.5rem;
}

.h-28px {
    height: 1.75rem;
}

.h-30px {
    height: 1.875rem;
}

.h-32px {
    height: 2rem;
}

.h-34px {
    height: 2.125rem;
}

.h-36px {
    height: 2.25rem;
}

.h-38px {
    height: 38px;
}

.h-40 {
    height: 2.5rem;
}

.h-44px {
    height: 2.75rem;
}

.h-46 {
    height: 2.875rem;
}

.h-48px {
    height: 3rem;
}

.h-50 {
    height: 3.125rem;
}

.h-52 {
    height: 3.25rem;
}

.h-54 {
    height: 3.375rem;
}

.h-56px {
    height: 3.5rem;
}

.h-58px {
    height: 3.625rem;
}

.h-60px {
    height: 3.75rem;
}

.h-62px {
    height: 3.875rem;
}

.h-64px {
    height: 4rem;
}

.h-66px {
    height: 4.125rem;
}

.h-68px {
    height: 4.25rem;
}

.h-70px {
    height: 4.375rem;
}

.h-72 {
    height: 4.5rem;
}

.h-74px {
    height: 4.625rem;
}

.h-76 {
    height: 4.75rem;
}

.h-78 {
    height: 4.875rem;
}

.h-82px {
    height: 5.125rem;
}

.h-84px {
    height: 5.25rem;
}

.h-90 {
    height: 5.625rem;
}

.h-94px {
    height: 5.875rem;
}

.h-96px {
    height: 6rem;
}

.h-100px {
    height: 6.25rem;
}

.h-106px {
    height: 6.625rem;
}

.h-116px {
    height: 7.25rem;
}

.h-118px {
    height: 7.375rem;
}

.h-130px {
    height: 8.125rem;
}

.h-140px {
    height: 8.75rem;
}

.h-144px {
    height: 9rem;
}

.h-146px {
    height: 9.125rem;
}

.h-148px {
    height: 9.25rem;
}

.h-175px {
    height: 10.938rem;
}

.h-182 {
    height: 11.375rem;
}

.h-190 {
    height: 11.875rem;
}

.h-198 {
    height: 12.375rem;
}

.h-200px {
    height: 12.5rem;
}

.h-206 {
    height: 12.875rem;
}

.h-210px {
    height: 13.125rem;
}

.h-216px {
    height: 13.5rem !important;
}

.h-218px {
    height: 13.625rem !important;
}

.h-220px {
    height: 13.75rem !important;
}

.h-212 {
    height: 13.25rem;
}

.h-240px {
    height: 15rem !important;
}

.h-250 {
    height: 15.625rem;
}

.h-254px {
    height: 15.875rem;
}

.h-256px {
    height: 16rem;
}

.h-268px {
    height: 16.75rem;
}

.h-294px {
    height: 18.375rem;
}

.h-296px {
    height: 18.5rem;
}

.h-320px {
    height: 20rem;
}

.h-324px {
    height: 20.25rem;
}

.h-328px {
    height: 20.5rem;
}

.h-252px {
    height: 15.75rem;
}

.h-316px {
    height: 19.75rem !important;
}

.h-358px {
    height: 22.375rem;
}

.h-362px {
    height: 22.625rem;
}

.h-366px {
    height: 22.875rem;
}

.h-380px {
    height: 23.75rem;
}

.h-392px {
    height: 24.5rem;
}

.h-410px {
    height: 25.625rem;
}

.h-422px {
    height: 26.375rem;
}

.h-470px {
    height: 29.375rem;
}

.h-max-500px {
    max-height: 31.25rem;
}

.h-534px {
    height: 33.375rem;
}

.h-max-600px {
    max-height: 37.5rem;
}

.h-70 {
    min-height: 70% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.h-fit-content {
    height: fit-content !important;
}

.min-h-70vh {
    min-height: 70vh;
}

.max-h-100 {
    max-height: 100%;
}

.max-h-84vh {
    max-height: 84vh;
}

.min-h-100vh {
    min-height: 100vh;
}

.max-h-inherit {
    max-height: inherit !important;
}

.max-h-130px {
    max-height: 8.125rem;
}

.h-30vh {
    height: 30vh !important;
}

.h-34vh {
    height: 34vh !important;
}

.h-40vh {
    height: 40vh !important;
}

.h-60vh {
    height: 60vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

/* min height */
.min-h-100vh {
    min-height: 100vh;
}

.min-h-30vh {
    min-height: 30vh !important;
}

.min-h-50vh {
    min-height: 50vh;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-start {
    justify-content: start;
}

//flex direction

.e-flex-direction-column {
    flex-direction: column;
}

.e-hover-btn {
    padding: 6px !important;
}

.e-sip-date:hover {
    background-color: #8a9ae2;
    color: #fff;
}

.object-fit-cover {
    object-fit: cover;
}

.vertical-align-sub {
    vertical-align: sub;
}

//truncate
.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.truncate-second-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.truncate-third-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.truncate-four-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.e-flex-wrap {
    flex-wrap: wrap;
}

// gap

.gap-12px {
    gap: 0.75rem;
}

.gap-2-all {
    gap: 0.125rem;
}

.gap-0-4px {
    gap: 0 0.25rem;
}

.gap-0-6px {
    gap: 0 0.375rem;
}

.gap-0-8 {
    gap: 0 0.5rem;
}

.gap-4-8 {
    gap: 0.25rem 0.5rem;
}

.gap-0-10 {
    gap: 0 0.625rem;
}

.e-gap-0-12px {
    gap: 0 0.75rem;
}

.e-gap-0-14 {
    gap: 0 0.875rem;
}

.gap-0-16 {
    gap: 0 1rem;
}

.e-gap-8-16 {
    gap: 0.5rem 1rem;
}

.e-gap-0-18px {
    gap: 1.125rem 0;
}

.gap-0-20px {
    gap: 0 1.25rem;
}

.gap-0-24px {
    gap: 0 1.5rem;
}

.gap-0-12px {
    gap: 0 0.75rem;
}

.gap-24-0 {
    gap: 1.5rem 0;
}

.gap-0-26px {
    gap: 0 1.625rem;
}

.gap-0-40 {
    gap: 0 2.5rem;
}

.gap-0-48 {
    gap: 0 3rem;
}

.gap-0-72 {
    gap: 0 4.5rem;
}

.gap-0-120 {
    gap: 0 7.5rem;
}

.gap-0-132 {
    gap: 0 8.25rem;
}

/*===============================
         Box shadow
================================= */

.e-box-shadow-business-stats {
    box-shadow: 0 0.25rem 6.25rem 0 #0000001f;
}

.e-leader-board-box-shadow {
    box-shadow: 0 0.063rem 0.5rem 0 #6e6e6e1a;
}

.e-admin-box-shadow {
    box-shadow: 0.5rem 1rem 3.5rem 0 #0000000a;
}

.e-box-tooltip-box-shadow {
    box-shadow: 0 0.25rem 0.25rem 0 #00000040;
}

/*===============================
         filter
================================= */

.e-filter-none {
    filter: none !important;
}

/*===============================
         Display flex
================================= */

.e-inline-flex {
    display: inline-flex;
    align-items: center;
}

.e-display-flex {
    display: flex;
    align-items: center;
}

.e-display-contents {
    display: contents;
}

/*===============================
         Resize
================================= */

.resize-none {
    resize: none;
}

.e-error-message {
    color: $red;
}

.e-disable {
    opacity: 0.5 !important;
    pointer-events: none;
}

//manage-bank modal scroll
// .e-manage-scrollbar::-webkit-scrollbar-thumb {
//     background: $egyptian_blue;
//     border-radius: 50rem;
// }

// .e-manage-scrollbar::-webkit-scrollbar {
//     width: 0.25rem;
//     height: 0.25rem;
//     display: block;
// }

.e-dropdown-more-wrapper .e-dropdown-more {
    box-shadow: 0 0 0.75rem 0 #00000033;
    width: 7.75rem;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem;
    position: absolute;
    z-index: 99;
}

.e-dropdown-more-wrapper .e-dropdown-more::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 70%;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

//Select box

.css-13cymwt-control,
.css-t3ipsp-control {
    border: 0.063rem solid $indigo !important;
    border-radius: 0.75rem !important;
    background: transparent !important;
    border-color: $indigo !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

// custom select
.e-select-box {
    svg {
        color: $raisin_black !important;
        width: 1rem;
        height: 1rem;
    }

    .css-1dimb5e-singleValue {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 24px;
        color: $raisin_black;
        font-family: "Noto Sans" !important;
    }


}

.css-1jqq78o-placeholder {
    font-family: "Noto Sans" !important;
    font-size: 0.875rem !important;
    font-weight: 300 !important;
    line-height: 1rem !important;
    color: $davys_grey !important;
}

.css-1n6sfyn-MenuList {
    max-height: 140px !important;

    // &::-webkit-scrollbar-thumb {
    //     background: $indigo !important;
    //     border-radius: 3rem !important;
    // }

    // &::-webkit-scrollbar {
    //     width: 0.125rem !important;
    //     display: block !important;
    // }
}

.css-1dimb5e-singleValue {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 24px;
    color: $raisin_black;
    font-family: "Noto Sans" !important;
}

.css-5rosin-control {
    background-color: transparent !important;
}

.Select-arrow {
    display: none !important;
}

.e-select-box .css-1n6sfyn-MenuList,
.css-1nmdiq5-menu {
    z-index: 9 !important;
    font-family: "Noto Sans" !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.125rem !important;
    color: $rich_black !important;
}

.css-1fdsijx-ValueContainer {
    padding-right: 0 !important;
}

.css-tr4s17-option {
    background-color: $indigo !important;
    pointer-events: none !important;
}

.e-select-box .css-1u9des2-indicatorSeparator {
    display: none;
}

.e-reg-select-box {
    svg {
        color: $raisin_black;
        width: 1rem;
        height: 1rem;
    }
}

// .e-reg-select-box .css-13cymwt-control {
//     border: 0.063rem solid transparent !important;
//     box-shadow: none !important;
// }

// .e-reg-select-box .css-t3ipsp-control {
//     border: 1px solid transparent !important;
//     box-shadow: none !important;
// }

// .css-1n6sfyn-MenuList {
//     font-family: 'Noto Sans' !important;
//     font-weight: 400;
//     font-size: 0.875rem !important;
//     line-height: 1.125rem;
//     color: $rich_black_light;
// }

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

// .e-reg-select-box .css-1fdsijx-ValueContainer {
//     padding-right: 0 !important;
//     text-align: end;
// }

.e-table-select {

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-radius: 0.25rem !important;
        border: 0.063rem solid transparent !important;
        border-color: transparent !important;
    }

    svg {
        color: $raisin_black;
        width: 1rem;
        height: 1rem;
    }
}

//Header Selector
.e-header-select-box {
    .css-1fdsijx-ValueContainer {
        padding-right: 0 !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem;
    }

    svg {
        color: $raisin_black !important;
        width: 1rem;
        height: 1rem;
    }
}

//modal selector
.e-modal-select {

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-radius: 0.5rem !important;
        background: transparent !important;
        border-color: $platinum_light !important;
        box-shadow: 0.063rem solid $indigo !important;
        font-weight: 400;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0.625rem 0.875rem !important;
    }

    .css-1dimb5e-singleValue {
        font-family: "Noto Sans" !important;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $raisin_black !important;
    }

    svg {
        width: 1rem;
        height: 1rem;
    }

    // .css-1k0rgcj-indicatorContainer {
    //     padding: 14px;
    // }
}

.e-section-select {

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-radius: 0.5rem !important;
        background: transparent !important;
        border-color: $platinum_light !important;
        box-shadow: 0.063rem solid $indigo !important;
        font-weight: 400;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0.563rem 0.875rem !important;
    }

    .css-1dimb5e-singleValue {
        font-family: "Noto Sans" !important;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $raisin_black !important;
    }

    svg {
        width: 1rem;
        height: 1rem;
    }

    // .css-1k0rgcj-indicatorContainer {
    //     padding: 14px;
    // }
}

//Tooltip

.e-tooltip .e-tooltip-content {
    visibility: hidden;
    width: 8rem;
    background: $white;
    border-radius: 0.5rem;
    position: absolute;
    z-index: 99;
    top: 2.125rem;
    left: 1.375rem;
}

.e-tooltip:hover .e-tooltip-content {
    visibility: visible;
}

//autocomplete search input
.e-autocomplete-wrapper {
    .sc-fqkvVR {
        border: 0.063rem solid $platinum_light;
        box-shadow: 0 0.063rem 0.125rem 0 #1018280d;
        border-radius: 0.5rem;
    }

    .wrapper {
        gap: 0.5rem 0;
        border-radius: 0.5rem !important;
        box-shadow: none !important;
        position: absolute !important;
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 0.063rem solid transparent !important;
        border-radius: 1.5rem;
        z-index: 99 !important;

        input {
            font-family: "Noto Sans" !important;
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 1rem;
            color: $raisin_black !important;
            padding-left: 0.5rem;
            padding-block: 0.75rem;

            &::placeholder {
                font-family: "Noto Sans";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1rem;
                color: $davys_grey;
            }
        }

        .dYrfwz {
            min-height: max-content;
        }

        ul {
            padding: 0 !important;
        }

        li {
            .search-icon {
                display: none !important;
            }

            border-bottom: 0.063rem solid $bright_gray_light;
            font-family: "Noto Sans" !important;
            font-size: 0.75rem;
            padding: 1.125rem 1rem !important;
            line-height: 0.625rem !important;
            cursor: pointer;
        }

        .ffXrxx {
            border: 1px solid #e3e5e5;
            box-shadow: 0.25rem 0.25rem 3rem 0 #07007c14;
            border-radius: 0.5rem;
            overflow: hidden;
        }

        .ffXrxx>div.line {
            display: none;
        }

        .ewTnKk {
            margin: -0.125rem 0.875rem 0 0 !important;
        }
    }
}

// ordered list style change )
.e-flash-quiz {
    ol {
        counter-reset: list;
    }

    ol>li {
        list-style: none;
    }

    ol>li:before {
        content: counter(list, lower-alpha) ") ";
        counter-increment: list;
    }

    li {
        &::before {
            padding-right: 0.5rem !important;
        }
    }
}

//overlay
.overlay-background {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
}

.e-custom-tab {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        height: 0.063rem !important;
        display: none !important;
    }
}

.e-chart-scroll {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        height: 0.25rem !important;
        width: 0.25rem !important;
        display: block !important;
    }
}

// offcanvas scroll
.e-offcanvas-wrapper {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.25rem;
        display: block !important;
    }
}

.e-push-notification {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .e-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0.125rem;
        bottom: 0;
        background: blue;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 1.875rem;

        &:before {
            position: absolute;
            content: "";
            height: 1.25rem;
            width: 1.25rem;
            left: 0.125rem;
            top: 0.125rem;
            background: white;
            box-shadow:
                0 0.25rem 0.75rem 0 rgba(255, 255, 255, 0.25) inset,
                0.125rem 0.25rem 0.25rem 0 rgba(255, 255, 255, 0.32) inset;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    input:checked+.e_slider {
        background-color: blue;
    }

    input:focus+.e_slider {
        box-shadow: 0 0 0.063rem transparent;
    }

    input:checked+.e_slider:before {
        -webkit-transform: translateX(1.375rem);
        -ms-transform: translateX(1.375rem);
        transform: translateX(1.375rem);
    }
}

// day picker styling
.e-day-picker {

    .rdp-day_disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .rdp-nav_button_previous {

        &[disabled] {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    .rdp-caption_label {
        font-family: "Noto Sans";
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.5rem;
        letter-spacing: 0.024rem;
    }

    .rdp-caption {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding-bottom: 0.25rem;
    }

    .rdp-head {
        height: 3.5rem;
    }

    .rdp-cell {
        padding: 0 !important;
    }

    .rdp-months {
        border-radius: 1rem;
        padding: 1rem;
    }

    .rdp-head_row {
        .rdp-head_cell {
            padding: 0 1rem !important;

            font-family: "Noto Sans";
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem;
            color: $arsenic;
        }
    }

    tr:nth-child(even) {
        background-color: $white !important;
    }

    button {
        box-shadow: none;
        border: 0;
        background: $white;
        color: $raisin_black;

        font-family: "Noto Sans";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        letter-spacing: -0.02rem;
        padding: 0.5rem;
        height: 2.375rem;
        width: 2.875rem;

        &:hover {
            background: $lavender_blue;
        }
    }

    .rdp-day_outside {
        color: $gray_light;
        pointer-events: none;
    }

    .rdp-day_selected {
        background: $indigo;
        color: $white;
        pointer-events: none;

        &:hover {
            background: $indigo;
        }
    }
}

// tab scroll
.e-tab-scroll {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        height: 0.125rem !important;
        display: none !important;
    }
}

// Time picker
.e-time-picker {
    .rc-time-picker-input {

        &::placeholder {
            font-family: "Noto Sans";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;
        }

        cursor: pointer;
        border: 0.063rem solid transparent;
        border-radius: 0.5rem;

        &:focus-visible {
            outline: none;
        }

        padding: 1.25rem 0.875rem;
        font-family: "Noto Sans";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $raisin_black;
    }

    img {
        position: absolute;
        right: 0.75rem;
        top: 0.813rem;
        cursor: pointer;
    }

    .e-close {
        position: absolute;
        right: 0.75rem;
        top: 0.688rem;
        cursor: pointer;
    }
}

.rc-time-picker-panel {
    width: auto !important;
}

.rc-time-picker-panel-inner {
    // width: 15.75rem;
    // top: 3.125rem;

    .rc-time-picker-panel-select {
        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            width: 0.125rem !important;
            display: block !important;
        }

        font-family: "Noto Sans";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $raisin_black;
    }

    .rc-time-picker-panel-input {
        font-size: 1rem;
        font-weight: 400;
        font-family: "Noto Sans";
        line-height: 1.5rem;
        color: $raisin_black;
    }
}

.e-app-zoom-level-9 {
    zoom: 0.9 !important;

    .max-h-zoom-100vh {
        max-height: 100vh !important;
    }
}

.e-app-zoom-level-8 {
    zoom: 0.8 !important;

    .max-h-zoom-100vh {
        max-height: 100vh !important;
    }
}

.e-app-zoom-level-7 {
    zoom: 0.6 !important;
}

// .e-jotun-logo {
//     &:hover {
//         transform: scale(1.02);
//     }
// }

.e-search-suggestion .css-1dimb5e-singleValue {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1rem;
    color: $raisin_black;
}

// auto complete search input
.e-search-suggestion {
    svg {
        display: none;
    }

    .css-1nmdiq5-menu {
        border-radius: 0.5rem;
        border: 0.063rem solid #e3e5e5;
        box-shadow: 0.25rem 0.25rem 3rem 0px #07007c14;
        background: $white;
        overflow: hidden;
    }

    .css-1n6sfyn-MenuList {
        padding: 0 !important;
    }

    .css-tr4s17-option,
    .css-10wo9uf-option,
    .css-d7l1ni-option {
        padding: 1.125rem 1rem !important;
        cursor: pointer;
        border-bottom: 0.063rem solid #ecedef;
        font-family: "Noto Sans";
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 0.625rem;
    }
}

// toast styles
.Toastify__toast-body {
    font-family: "Noto Sans";
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: $raisin_black;
}

.epZUJV,
.fAiwtk {
    display: none !important;
}

// /pagination

.e-pagination {
    display: flex;
    gap: 0 1rem;

    .e-next-button.disabled {
        opacity: 0.6 !important;
        pointer-events: none;
    }

    .e-prev-button.disabled {
        opacity: 0.6 !important;
        pointer-events: none;
    }
}

.e-pagination li {
    display: flex;
    border-radius: 50%;
    background: $white;
    cursor: pointer;
}

.e-page-item a {
    color: $raisin_black !important;
    font-family: "Noto Sans" !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    text-decoration: none;
    padding: 0.563rem 16px;
    margin-block: auto;
}

.break a {
    color: $raisin_black !important;
    font-family: "Noto Sans" !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    text-decoration: none;
    padding: 0.625rem 1rem;
}

.e-pagination .e-next-button a {
    display: flex;
    text-decoration: none !important;
    padding: 0 0.25rem;
    color: $raisin_black !important;
    font-family: "Noto Sans";
    font-size: 0.875rem;
    font-weight: 500;
}

// .e-next-button,
// .disabled {
//     opacity: 0.7 !important;
// }

.e-pagination .e-prev-button a {
    display: flex;
    text-decoration: none !important;
    padding: 0 0.25rem;
    color: $raisin_black !important;
    font-family: "Noto Sans";
    font-size: 0.875rem;
    font-weight: 500;
}

.e-pagination .e-prev-button img {
    rotate: 180deg;
}

.e-pagination .e-active {
    border-radius: 50%;
    background: $indigo;
    backdrop-filter: blur(50px);
}

.e-pagination .e-active a {
    color: $white !important;
}


.e-light-pagination {
    li {
        display: flex;
        border-radius: 0.5rem;
        background: $platinum_light;
        cursor: pointer;
        opacity: 0.5;

    }

    .e-active {
        border-radius: 0.5rem;
        background: $platinum_light;

        a {
            color: $raisin_black !important;
        }

        opacity: 1 !important;
    }

    a {
        color: $raisin_black !important;
        font-family: "Noto Sans" !important;
        font-size: 0.875rem !important;
        font-weight: 500 !important;
        text-decoration: none;
        padding: 0.25rem 1rem;
        margin-block: auto;
    }

    .e-next-button {
        opacity: 1;

        a {
            display: flex;
            text-decoration: none !important;
            padding: 0.25rem 0.25rem;
            color: $raisin_black !important;
            font-family: "Noto Sans";
            font-size: 0.875rem;
            font-weight: 500;
        }

    }

    .e-next-button.disabled {
        opacity: 0.5 !important;

    }

    .e-prev-button.disabled {
        opacity: 0.5 !important;

    }

    .e-prev-button {
        opacity: 1;

        a {
            display: flex;
            text-decoration: none !important;
            padding: 0.25rem 0.25rem;
            color: $raisin_black !important;
            font-family: "Noto Sans";
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    .break a {
        color: $raisin_black !important;
        font-family: "Noto Sans" !important;
        font-size: 0.875rem !important;
        font-weight: 500 !important;
        text-decoration: none;
        padding: 0.5rem 14px;
    }
}

//wysiwyg editor
.demo-wrapper {
    &::-webkit-scrollbar {
        height: 0.25rem !important;
        display: block !important;
    }

    border: 0.063rem solid $platinum_light;
    border-radius: 0.5rem;
    overflow: auto;

    .rdw-editor-toolbar {
        border: 0.063rem solid transparent;
        padding: 0.625rem 0.5rem 0;
        width: max-content;
        // border-bottom: 0.063rem solid $platinum_light;
    }

    .rdw-link-modal {
        left: auto !important;
        right: 0;
    }

    .rdw-link-modal-target-option {
        margin-bottom: 4px;
    }

    .demo-editor {
        cursor: text;
        border-top: 0.063rem solid $platinum_light;

        .public-DraftEditor-content {
            padding-inline: 1rem !important;
            padding-block: 0.75rem !important;
        }

        span {
            font-family: "Noto Sans";
        }

        height: 150px;

        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            width: 0.25rem !important;
            display: block !important;
        }

        .public-DraftStyleDefault-block {
            margin-top: 0 !important;
            margin-bottom: 0px !important;
        }
    }

    .rdw-dropdown-optionwrapper {
        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            width: 0.25rem !important;
            display: block !important;
        }
    }

    .rdw-option-wrapper {
        padding: 0.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.25rem !important;
        display: block !important;
    }
}

.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    transition: ease 0.5s;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .custom-checkbox {
    position: absolute;
    left: 40%;
    height: 16px;
    width: 16px;
    border: 1.5px solid #000000;
    box-sizing: border-box;
    transition: all 0.5s;
    border-radius: 2px;
    color: transparent;
    border-radius: 2px;
}

.checkbox-container .custom-checkbox:hover {
    border: 1px solid $indigo;
}

.checkbox-container .custom-checkbox::after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 5px;
    height: 11px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input:checked~.custom-checkbox::after {
    display: block;
}

input:checked~.custom-checkbox {
    background-color: $indigo !important;
    border: 0.063rem solid $indigo;
}

#checkbox-outline.form-check-input {
    background: url("../Assets//Image/checkbox-outline-unchecked.svg");
    border: none;
    width: 24px;
    height: 24px;
}

input:checked#checkbox-outline.form-check-input {
    background: url("../Assets//Image/checkbox-outline-checked.svg");
}


.e-html-parser {
    font-family: "Noto Sans";
}

.e-scroll {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.125rem !important;
        display: block !important;
    }
}

.e-modal-body-scrollbar {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.25rem !important;
        display: block !important;
    }
}

// .DayPickerInput-Overlay {
//     bottom: 40px;
// }

// .e-scroll-bar-none {
//     overflow-x: hidden;

//     &::-webkit-scrollbar-thumb {
//         background: $indigo !important;
//         border-radius: 3rem !important;
//     }

//     &::-webkit-scrollbar {
//         width: 0.25rem !important;
//         display: none !important;
//     }
// }